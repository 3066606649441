<template>
  <div class="voucherset">
    <NavBar :list="navList" :router="false" name="收费凭证"></NavBar>
    <div class="public-box">
      <div class="set">
        <MyButton @click="codeSet(1)" :accessId="59831">
          <template slot="preImage">
            <img src="../../../assets/img/icon/pzhby.png" alt="" />
          </template>
          <span>凭证编号编译规则设置</span>
        </MyButton>
        <MyButton left @click="setmodevisible = true" :accessId="2644">
          <template slot="preImage">
            <img src="../../../assets/img/icon/pzhby.png" alt="" />
          </template>
          <span>模板设置</span>
        </MyButton>
        <MyButton left @click="codeSet(2)" :accessId="2643">
          <template slot="preImage">
            <img src="../../../assets/img/icon/dzzsc.png" alt="" />
          </template>
          <span>电子章上传</span>
        </MyButton>
      </div>
      <div style="margin-bottom: 20px">
        <span style="margin-right: 10px">启用模板:</span>
        <span>
          <!-- <el-radio @change="contractNoConfirm" v-model="contractNoRuleForm.values.electronicSealTpl" :label="1">模板一</el-radio> -->
          <el-radio
            @change="contractNoConfirm"
            v-model="contractNoRuleForm.values.electronicSealTpl"
            :label="2"
            >模板一</el-radio
          >
        </span>
      </div>
      <component
        :title="contractNoRuleForm.values.voucherName"
        :notes="contractNoRuleForm.values.notes"
        :is="
          contractNoRuleForm.values.electronicSealTpl == 1
            ? 'voucherVue'
            : 'voucherVue1'
        "
      ></component>
      <!-- <voucher-vue></voucher-vue> -->
    </div>
    <!-- 上传 -->
    <el-dialog
      title="电子章上传"
      :visible.sync="upvisible"
      @close="contractNoClose"
      :close-on-click-modal="false"
    >
      <el-upload
        class="avatar-uploader"
        action=""
        :show-file-list="false"
        accept="image/*"
        :on-change="fileChange"
        :auto-upload="false"
      >
        <img
          v-if="contractNoRuleForm.values.electronicSeal"
          :src="contractNoRuleForm.values.electronicSeal"
          class="avatar"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <template slot="footer">
        <MyButton @click="upvisible = false">取消</MyButton>
        <MyButton left type="primary" @click="contractNoConfirm(1)"
          >确定</MyButton
        >
      </template>
    </el-dialog>

    <!-- 凭证编号 -->
    <el-dialog
      :visible.sync="codeVisible"
      title="凭证编号编译规则设置"
      width="880px"
      @close="contractNoClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="contractNoRuleForm"
        ref="contractNoRuleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="合同编号组成:">
          <div class="listBox">
            <div class="listHead">
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="segmentation">-</div>
              <div class="list">2</div>
              <div class="list">0</div>
              <div class="list">2</div>
              <div class="list">2</div>
              <div class="list">0</div>
              <div class="list">2</div>
              <div class="list">2</div>
              <div class="list">3</div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
            </div>
            <div class="listName">
              <div class="list">1</div>
              <div class="list">2</div>
              <div class="list">3</div>
              <div class="list">4</div>
              <div class="segmentation">-</div>
              <div class="list">5</div>
              <div class="list">6</div>
              <div class="list">7</div>
              <div class="list">8</div>
              <div class="list">9</div>
              <div class="list">10</div>
              <div class="list">11</div>
              <div class="list">12</div>
              <div class="list">13</div>
              <div class="list">14</div>
              <div class="list">15</div>
              <div class="list">16</div>
              <div class="list">17</div>
              <div class="list">18</div>
            </div>
          </div>
        </el-form-item>
        <el-divider></el-divider>
        <div class="contractNoBox">
          <div class="left">
            <el-form-item label="1号字母设置:">
              <el-input
                placeholder="请输入1号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber1"
                class="public-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="3号字母设置:">
              <el-input
                placeholder="请输入3号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber3"
                class="public-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="16号字母设置:">
              <el-input
                placeholder="请输入16号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber16"
                class="public-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="18号字母设置:">
              <el-input
                placeholder="请输入18号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber18"
                class="public-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="2号字母设置:">
              <el-input
                placeholder="请输入2号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber2"
                class="public-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="4号字母设置:">
              <el-input
                placeholder="请输入4号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber4"
                class="public-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="17号字母设置:">
              <el-input
                placeholder="请输入17号字母"
                :maxlength="1"
                v-model="contractNoRuleForm.values.contractNumber17"
                class="public-input"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <el-divider></el-divider>
        <p>
          说明：凭证编号的1-4位字母由市场自行设置,若没有设置则默认为MYPZ;5-12位为凭证生成时间；13-15位为当前生成凭证数量编号（如当日第一份凭证，则为：001）;16-18为由市场自行设置，若没设置则为空
        </p>
      </el-form>
      <template slot="footer">
        <MyButton @click="codeVisible = false" right>取消</MyButton>
        <MyButton @click="contractNoConfirm(2)" type="primary">确定</MyButton>
      </template>
    </el-dialog>

    <el-dialog
      title="凭证设置"
      :visible.sync="setmodevisible"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="80px">
        <el-form-item label="凭证名称:">
          <el-input
            v-model="contractNoRuleForm.values.voucherName"
            class="public-input"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="说明:">
          <el-input
            type="textarea"
            autosize
            v-model="contractNoRuleForm.values.notes"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <MyButton @click="setmodevisible = false">取消</MyButton>
        <MyButton left @click="setvoucher" type="primary">确定</MyButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import voucherVue from "../../../components/voucher.vue";
import voucherVue1 from "../../../components/voucher1.vue";

export default {
  components: {
    voucherVue,
    voucherVue1,
  },
  data() {
    return {
      navList: [
        {
          name: "缴费凭证",
          path: "/merchantbill",
        },
      ],
      upvisible: false,
      codeVisible: false,
      imageUrl: "",
      contractNoRuleForm: {
        values: {
          contractNumber3: "P",
          contractNumber4: "Z",
          contractNumber1: "M",
          contractNumber2: "Y",
          contractNumber16: "",
          contractNumber17: "",
          contractNumber18: "",
          electronicSeal: "",
          electronicSealTpl: 2,
          voucherName: "",
          notes: "",
        },
        describe: "收费编号设置",
        key: "chargeNumberSetting",
      },
      model: 0,
      setmodevisible: false,
    };
  },
  created() {
    this.codeSet();
  },
  methods: {
    codeSet(type) {
      this.$request
        .HttpGet("/setting/edit", {
          key: "chargeNumberSetting",
        })
        .then((res) => {
          if (res.data) {
            this.contractNoRuleForm = res.data;
          }
          if (type) {
            if (type == 1) {
              this.codeVisible = true;
            } else {
              this.upvisible = true;
            }
          }
        });
    },
    contractNoConfirm() {
      this.$request
        .HttpPost("/setting/add", this.contractNoRuleForm)
        .then((res) => {
          this.$common.notifySuccess("操作成功");
          this.codeVisible = false;
          this.upvisible = false;
          this.setmodevisible = false;
        });
    },
    contractNoClose() {},
    fileChange(file) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.contractNoRuleForm.values.electronicSeal =
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
      });
    },
    setvoucher() {
      this.contractNoConfirm();
      console.log(this.contractNoRuleForm.values);
    },
  },
};
</script>
<style lang="scss" scoped>
.voucherset {
  box-sizing: border-box;
  .set {
    height: 50px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .labelwidth {
    width: 200px;
  }
  .listBox {
    .listHead {
      display: flex;
      align-items: center;
      .list {
        width: 15px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #333333;
        box-sizing: border-box;
        margin-right: 5px;
      }
      .segmentation {
        margin-right: 5px;
      }
    }
    .listName {
      display: flex;
      .list {
        width: 15px;
        text-align: center;
        box-sizing: border-box;
        margin-right: 5px;
        line-height: normal;
      }
      .segmentation {
        margin-right: 5px;
        line-height: normal;
      }
    }
  }
  .contractNoBox {
    display: flex;
    .left,
    .right {
      flex: 1;
    }
  }
}
</style>
