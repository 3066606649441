<template>
  <div class="voucher">
    <div class="dash">
      <div class="title">{{ title }}</div>
      <div class="time-info">
        <div></div>
        <div>
          <span>凭证号:</span>
          <span style="color: #807d7d">{{ code }}</span>
        </div>
      </div>
      <div class="time-info">
        <div>
          <span>业主姓名:</span>
          <span style="color: #807d7d">{{ username }}</span>
        </div>
        <div>
          <span>收费时间:</span>
          <span style="color: #807d7d">{{ time }}</span>
        </div>
      </div>
      <div class="main-info">
        <div class="item">
          <div>
            <span>收费金额:</span>
            <span class="msg">{{ money }}</span>
          </div>
          <div>
            <span>收费方式:</span>
            <span class="msg">{{ chargewey }}</span>
          </div>
        </div>
        <div class="item detail">
          <span>收费详情:</span>
          <span>本次收取基础费用:</span>
          <span class="msg">{{ rent }}</span>
          <span>元,</span>
          <span>日常费用:</span>
          <span class="msg">{{ daliy }}</span>
          <span>元,</span>
          <span>其他:</span>
          <span class="msg">{{ other }}</span>
          <span>元</span>
        </div>
        <div class="item">
          <div>
            <span>备注:</span>
            <span class="msg">{{ remark }}</span>
          </div>
        </div>
        <div class="tips" v-if="notes">
          <span>说明:</span>
          <span v-html="notes"></span>
        </div>
        <div class="kp-info">
          <div class="items">
            <div>
              <span>开票人:</span>
              <span class="msg">{{ drawer }}</span>
            </div>
          </div>
          <div class="items">
            <div class="gz">
              <span>公章:</span>
              <span>{{ commonSeal }}</span>
            </div>
            <div>
              <span>收款人:</span>
              <span class="msg" style="color: #807d7d">{{ payee }}</span>
            </div>
          </div>
        </div>
        <!-- <img :src="yzImage" v-img v-preview class="cachet" alt /> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "voucher",
  props: {
    time: {
      default:"<缴费时间>"
    },
    code: {
      default:"<凭证号>"
    },
    username: {
      default:"<业主姓名>"
    },
    money: {
      default:"<收费金额>"
    },
    chargewey: {
      default:"<收费方式>"
    },
    rent: {
      default:"<基础费用>"
    },
    water: {
      default:"<水费>"
    },
    ele: {
      default:"<电费>"
    },
    gas: {
      default:"<气费>"
    },
    daliy: {
      default:"<日常费用>"
    },
    other: {
      default:"<其他>"
    },
    remark: {
      default:"<备注>"
    },
    drawer: {
      default:"<开票人>"
    },
    commonSeal: {
      default:"<公章>"
    },
    payee: {
      default:"<收款人>"
    },
    title: {
      default:''
    },
    notes: {
      default:''
    },
    // yzImage:''
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.voucher {
  width: 1192px;
  min-height: 560px;
  background: #fafafa;
  border: 1px solid #dedede;
  // box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  .msg {
    color: #807d7d;
  }
  .dash {
    width: 1184px;
    min-height: 550px;
    background: #ffffff;
    border: 1px dashed #dedede;
    padding: 36px 136px 29px 136px;
    box-sizing: border-box;
    position: relative;
  }
  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    width: 100%;
    text-align: center;
  }
  .time-info {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    // border-bottom: 1px solid #dedede;
    // margin-top: 14px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .main-info {
    margin-top: 28px;
    padding-left: 24px;
    box-sizing: border-box;
    .item {
      height: 30px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dedede;
      justify-content: space-between;
      .msg {
        width: 140px;
        height: 15px;
        line-height: 15px;
        display: inline-block;
        // border-bottom: 1px solid #999999;
        padding-left: 15px;
        box-sizing: border-box;
        // background-color: red
      }
    }
    .detail {
      margin: 28px 0;
      justify-content: flex-start;
      .msg {
        width: 88px;
      }
    }
  }
  .kp-info {
    margin-top: 70px;
    // @extend .time-info;
    border-bottom: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .items {
      div {
        height: 30px;
        border-bottom: 1px solid #dedede;
        line-height: 30px;
      }
      .gz {
        border-bottom: none;
      }
    }
    .msg {
      width: 140px;
      // height: 30px;
      display: inline-block;
      // border-bottom: 1px solid #999999;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .cachet {
    width: 141px;
    height: 150px;
    position: absolute;
    opacity: 0.8;
    // object-fit: contain;
    right: 40px;
    bottom: 0;
    // border-radius: 50%;
  }
  .tips {
    width: 100%;
    font-size: 12px;
    margin-top: 25px;
    // width: 250px;
    // span{
    //   display: inline-block;
    //   width: 100%;
    // }
    p {
      text-indent: 35px;
    }
    div{
      display: inline-block;
      // width: 100%;
    }
  }
}
</style>
